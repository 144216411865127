import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ElementRef,
  ViewChild,
  Input,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { StatisticsParams, Venue } from '../../../../utils/CommonInterfaces';
import { NgStrapiAuthService } from '../../../../services/strapi/auth/ng-strapi-auth.service';
import { environment } from '../../../../../environments/environment';
import moment from 'moment';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  transform(
    value: string,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}

@Component({
  selector: 'app-grafana-charts-view',
  templateUrl: './grafana-charts-view.component.html',
  styleUrls: ['./grafana-charts-view.component.scss'],
})
export class GrafanaChartsViewComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;
  @Input() trendsGroup: Venue[];
  @Input() crossGroupA: Venue[];
  @Input() crossGroupB: Venue[];

  @ViewChild('iframe') iframe: ElementRef;

  private secureToken: string;
  private grafanaDashboards: string;
  public isMobile = false;
  public iframeHeight = 0;
  public resizeEventTimeout: ReturnType<typeof setTimeout>;
  public forceHideCharts: boolean;

  constructor(private authService: NgStrapiAuthService) {
    this.secureToken = this.authService.jwt;
    this.grafanaDashboards = this.authService.user.brand.dashboardIds;
  }

  /**
   * Used to debug the params passed to the grafana iframe
   *
   * @returns JSON stringified object
   */
  renderStatsParamsStringified() {
    const { pacesetter_venue, chartsSettings, venueId } = this.venueData;
    const venueDataObject = {
      grafanaUrl: environment.grafanaUrl,
      grafanaDashboard: this.grafanaDashboards,
      selectedGrafanaId: venueId,
      venueId: pacesetter_venue.id,
      percentDeviationMaxMin: chartsSettings.percentDeviationMaxMin,
      targetTotalDuration: chartsSettings.targetTotalDuration,
      targetPeakTotalDuration: chartsSettings.targetPeakTotalDuration,
      targetHoursOfDemand: chartsSettings.targetHoursOfDemand,
      targetQueueOccupancy: chartsSettings.targetQueueOccupancy,
      targetMaxTPH: chartsSettings.targetMaxTPH,
      secureToken: this.secureToken,
    };

    return JSON.stringify(
      { ...this.statisticsParams, ...venueDataObject },
      null,
      3
    );
  }

  /**
   * Adds a window listener to check the screen size,
   * and sets the isMobile variable accordingly
   */
  determineScreenSize() {
    const divWidth = document.getElementById('mainChartContainer').offsetWidth;

    if (divWidth >= 816) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    window.addEventListener(
      'message',
      (event) => {
        if (event.data.divHeight !== undefined) {
          this.iframeHeight = event.data.divHeight + 40;
        }
        // console.log('this.iframeHeight', this.iframeHeight);
        // console.log('event.data.forceReload', event.data.forceReload);
        if (event.data.forceReload) {
          this.forceHideCharts = true;
          setTimeout(() => {
            this.forceHideCharts = false;
          }, 1000);
        }
      },
      false
    );
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    clearTimeout(this.resizeEventTimeout);
    this.resizeEventTimeout = setTimeout(() => {
      this.determineScreenSize();
    }, 100);
  }

  /**
   * Creates the URL to be used in the iframe
   *
   * @param panelId
   * @returns
   */
  grafanaUrlBuilder(panelId) {
    if (!this.statisticsParams) {
      return;
    }
    //
    // console.log('this.venueData', this.venueData);
    // console.log('this.crossGroupA', this.crossGroupA);
    // console.log('this.crossGroupB', this.crossGroupB);
    // console.log('this.statisticsParams', this.statisticsParams);
    const grafanaType = this.isMobile ? 'mobile' : 'desktop';
    const grafanaDashboard = this.grafanaDashboards[grafanaType][this.statisticsParams.viewType];
    // console.log('grafanaDashboard', grafanaDashboard);

    const weeklyTypes = ['weekly', 'trends', 'cross', 'export', 'overview'];

    if (
      this.statisticsParams.viewType === 'weekly' ||
      this.statisticsParams.viewType === 'daily' ||
      this.statisticsParams.viewType === 'trends' ||
        this.statisticsParams.viewType === 'cross' ||
        this.statisticsParams.viewType === 'export' ||
        this.statisticsParams.viewType === 'overview'
    ) {
      let weekdayString = '';
      if ( weeklyTypes.includes(this.statisticsParams.viewType)) {
        this.statisticsParams.weekDaysSelected.map((weekdayName) => {
          weekdayString += 'var-weekdays=' + weekdayName;
          weekdayString += '&';
        });
      } else {
        weekdayString =
          'var-weekdays=' + moment(this.statisticsParams.selectedDate).day();
        weekdayString += '&';
      }

      let timeslotsString = '';
      this.statisticsParams.timeslotsSelected.map((timeslotName) => {
        timeslotsString +=
          `var-time_gap=tsrange%28%271995-10-26+${this.venueData.timeslots[timeslotName][0][0]}` +
          `%3A${this.venueData.timeslots[timeslotName][0][1]}%3A00%27%2C%271995-10-26
            +${this.venueData.timeslots[timeslotName][1][0]}%3A` +
          `${this.venueData.timeslots[timeslotName][1][1]}%3A00%27%29`;
        timeslotsString += '&';
      });

      const targetGap = String(
        moment().diff(this.statisticsParams.periodStartDate, 'week')
      );

      const periodStartDate =
          weeklyTypes.includes(this.statisticsParams.viewType)
          ? this.statisticsParams.periodStartDate.format('YYYY-MM-DD')
          : this.statisticsParams.selectedDate.format('YYYY-MM-DD');
      const periodEndDate =
          weeklyTypes.includes(this.statisticsParams.viewType)
          ? this.statisticsParams.periodEndDate.format('YYYY-MM-DD')
          : this.statisticsParams.selectedDate.format('YYYY-MM-DD');

      let periodStartDateEpoch = this.statisticsParams.periodStartDateEpoch;
      let periodEndDateEpoch = this.statisticsParams.periodEndDateEpoch;

      if (this.statisticsParams.viewType === 'daily') {
        let earliestHour = 24;
        let latestHour = 0;
        this.statisticsParams.timeslotsSelected.map((timeslotName) => {
          if (this.venueData.timeslots[timeslotName][0][0] < earliestHour) {
            earliestHour = Number(this.venueData.timeslots[timeslotName][0][0]);
          }
          if (this.venueData.timeslots[timeslotName][1][0] > latestHour) {
            latestHour = Number(this.venueData.timeslots[timeslotName][1][0]);
          }
        });
        periodStartDateEpoch = this.statisticsParams.selectedDate
          .clone()
          .hour(earliestHour + 1)
          .valueOf();
        periodEndDateEpoch = this.statisticsParams.selectedDate
          .clone()
          .hour(latestHour)
          .valueOf();
      }

      // &var-venueid=ls&var-venueid=bx
      // &var-compare_with=argyll&var-compare_with=bx
      // ["ls", "bx"] => &var-venueid=ls&var-venueid=bx
      let selectedVenuesString = '';
      if(this.statisticsParams.viewType === 'cross') {
        this.crossGroupA.map((venueData) => {
            selectedVenuesString += '&var-venueid=' + venueData.venueId;
        });
        this.crossGroupB.map((venueData) => {
          selectedVenuesString += '&var-compare_with=' + venueData.venueId;
        });
        // selectedVenuesString =
      } else if (this.statisticsParams.viewType === 'trends') {
        this.trendsGroup.map((venueData) => {
          selectedVenuesString += '&var-venueid=' + venueData.venueId;
        });
      } else if (this.statisticsParams.viewType === 'export' || this.statisticsParams.viewType === 'overview') {
        this.trendsGroup.map((venueData) => {
          selectedVenuesString += '&var-venueid=' + venueData.venueId;
        });
      } else {
        selectedVenuesString = '&var-venueid=' + this.venueData.venueId;
      }
      let groupByString = '';
      if (this.statisticsParams.viewType === 'export') {
        if(this.statisticsParams.groupBy === 'day') {
            groupByString = '&var-week_month=week';
        }
        if(this.statisticsParams.groupBy === 'week') {
          groupByString = '&var-week_month=month';
        }
      }
      if (this.statisticsParams.viewType === 'daily') {
        groupByString = '&var-sub_group=hour';
      }

      const extraParamsFromDb = this.statisticsParams.viewType === 'overview' ? 'orgId=1' : '?orgId=1';
      const url = environment.grafanaUrl +
          '/d/' +
          grafanaDashboard +
          // '?orgId=1' +
          extraParamsFromDb +
          selectedVenuesString +
          '&' +
          weekdayString +
          timeslotsString +
          'var-target_gap=' +
          targetGap +
          '&var-start_date=' +
          periodStartDate +
          '&var-end_date=' +
          periodEndDate +
          '&from=' +
          periodStartDateEpoch +
          '&to=' +
          periodEndDateEpoch +
          groupByString +
          '&var-pacesetter=' +
          this.venueData.pacesetter_venue.id +
          '&var-percentDeviationMaxMin=' +
          this.venueData.chartsSettings.percentDeviationMaxMin +
          '&var-targetTotalDuration=' +
          this.venueData.chartsSettings.targetTotalDuration +
          '&var-targetPeakTotalDuration=' +
          this.venueData.chartsSettings.targetPeakTotalDuration +
          '&var-targetHoursOfDemand=' +
          this.venueData.chartsSettings.targetHoursOfDemand +
          '&var-targetQueueOccupancy=' +
          this.venueData.chartsSettings.targetQueueOccupancy +
          '&var-targetMaxTPH=' +
          this.venueData.chartsSettings.targetMaxTPH +
          '&var-targetCoversPerDay=' +
          this.venueData.chartsSettings.targetCoversPerDay +
          '&var-targetTableOccupancy=' +
          this.venueData.chartsSettings.targetTableOccupancy +
          '&var-targetConversion=' +
          this.venueData.chartsSettings.targetConversion +
          '&var-targetPasserBysPerDay=' +
          this.venueData.chartsSettings.targetPasserBysPerDay +
          '&theme=light&authToken=' +
          this.secureToken +
          '&kiosk';
      return url;
    }

    if (this.statisticsParams.viewType === 'live') {
      return (
        environment.grafanaLiveScreenUrl +
        '/d/' +
        grafanaDashboard +
        '?orgId=1&var-venueid=' +
        this.venueData.venueId +
        '&from=now-1h&to=now&refresh=30s&theme=light&authToken=' +
        this.secureToken +
        '&kiosk'
      );
    }
    // '&panelId=' + panelId;
    // +
    // this.groupingString;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.determineScreenSize();
  }

  ngOnDestroy() {
    // The windows listener is added in determineScreenSize(), so it should be removed here
    window.removeEventListener('message', () => {});
  }
}
