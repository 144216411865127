<div class="live-screen" *ngIf="liveScreenReady">
  <!-- TOP YUSEN/TEMPURA/CASHIER CARDS -->
  <div class="grid-container__top"
       [ngClass]="{ 'competitionIn': !displayCompetition && !isEmbedded, 'competitionOut': displayCompetition && !isEmbedded}"
  >
    <ng-container *ngFor="let durationData of chartsData.durations">
      <div
        class="grid-container--card"
        [ngClass]="{
          'bg-yusen': durationData.name === 'Yusen',
          'bg-tempura': durationData.name === 'Tempura',
          'bg-cashier': durationData.name === 'Cashier',
          'bg-order': durationData.name === 'Order',
          'bg-kitchen': durationData.name === 'Kitchen',
          'bg-payment': durationData.name === 'Payment',
          'bg-queue': durationData.name === 'Queue',
          'bg-tills': durationData.name === 'Tills',
          'bg-coffee': durationData.name === 'Coffee',
          statusGood: durationData.status === 'good',
          statusBad: durationData.status === 'bad',
          statusCritical: durationData.status === 'critical',
          alert: durationData.alert
        }"
      >
        <div class="grid-container--card-stats">
          <div
            class="grid-container--card-title"
            [ngClass]="{
              alert: durationData.alert
            }"
          >
            {{ durationData.name }}
            <div
              class="grid-container--card-subtitle"
              [ngClass]="{
                alert: durationData.alert
              }"
            >
              {{ durationData.subtitle }}
            </div>
          </div>

          <div class="metrics-container">
            <div class="metrics-value" [ngClass]="{}">
              {{ durationData.value }}
            </div>
            <ng-container
              *ngTemplateOutlet="arrow; context: { durationData: durationData }"
            >
            </ng-container>
          </div>
        </div>

        <ng-container
          *ngTemplateOutlet="busyness; context: { durationData: durationData }"
        >
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="grid-container__bottom"
       [ngClass]="{ 'competitionIn': !displayCompetition && !isEmbedded, 'competitionOut': displayCompetition && !isEmbedded}"
  >
    <!-- MIDDLE AVG DURATION CARD -->
    <div
      class="average-duration grid-container--card"
      [ngClass]="{
        statusGood: chartsData.totalDuration.status === 'good',
        statusBad: chartsData.totalDuration.status === 'bad',
        statusCritical: chartsData.totalDuration.status === 'critical',
        alert: chartsData.totalDuration.alert
      }"
    >
      <div class="average-duration--card">
        <div class="average-duration--card__metrics-container">
          <div class="average-duration--card-title">
            {{ chartsData.totalDuration.title }}:
          </div>
          <div class="metrics-container">
            <div class="metrics-value">
              {{ chartsData.totalDuration.value }}
            </div>

            <ng-container
              *ngTemplateOutlet="
                arrow;
                context: { durationData: chartsData.totalDuration }
              "
            >
            </ng-container>
          </div>
        </div>

        <div class="average-duration--card__traffic-container">
          <ng-container
            *ngTemplateOutlet="
              busyness;
              context: { durationData: chartsData.totalDuration }
            "
          >
          </ng-container>
        </div>
      </div>
    </div>

    <!-- BOTTOM TRANSCATION/COVER CARD -->
    <div class="transactions grid-container--card">
      <ng-container *ngFor="let sliderData of chartsData.slider; let i = index">
        <div
          class=""
          style="display: flex; flex: 1"
          [@inOutAnimation]
          *ngIf="sliderData.type === 'comparison' && sliderIndex == i"
        >
          <div class="transactions--card">
            <div class="transactions--card__metrics-container">
              <div class="transactions--card-title">
                {{ sliderData.timeGap }} hour gap
              </div>
              <div class="transactions--card__title-container">
                <div class="transactions--card__title-container-title">
<!--                  grid-container&#45;&#45;card-title-->
                  {{ sliderData.title }}:
                </div>
                <div class="transactions--card__title-container-title-value">
<!--                  grid-container&#45;&#45;card-title-->
                  {{ sliderData.value }}
                </div>
              </div>
            </div>

            <div class="transactions--card__comparison-charts-container">
              <!-- last week -->
              <div
                class="transactions--card__comparison-charts-container--item"
              >
                <div class="transactions--graph-title">Last week</div>
                <div class="transactions--graph-container">
                  <div class="transactions--graph-container__value">
                    {{ sliderData.lastWeek }}
                  </div>
                  <mat-spinner
                    [mode]="'determinate'"
                    [value]="sliderData.value / (sliderData.lastWeek / 100)"
                  ></mat-spinner>
                </div>
              </div>

              <!-- top in chain -->
              <div
                class="transactions--card__comparison-charts-container--item"
              >
                <div class="transactions--graph-title">Top in chain</div>
                <div class="transactions--graph-container">
                  <div class="transactions--graph-container__value">
                    {{ sliderData.chain }}
                  </div>
                  <mat-spinner
                    [mode]="'determinate'"
                    [value]="sliderData.value / (sliderData.chain / 100)"
                  ></mat-spinner>
                </div>
              </div>

              <!-- venue record -->
              <div
                class="transactions--card__comparison-charts-container--item"
              >
                <div class="transactions--graph-title">Venue record</div>
                <div class="transactions--graph-container">
                  <div class="transactions--graph-container__value">
                    {{ sliderData.record }}
                  </div>
                  <mat-spinner
                    [mode]="'determinate'"
                    [value]="sliderData.value / (sliderData.record / 100)"
                  ></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <app-competition-embedded *ngIf="!isEmbedded" [competitionData]="competitionData" [loadingData]="false"
                            [isStandalone]="true"
                            [autoScroll]="true"
                            [competitionAutoScrollSpeed]="competitionAutoScrollSpeed"
                            [displayCompetition]="displayCompetition"
                            class="competition-embedded" [ngClass]="{ 'competitionIn': displayCompetition, 'competitionOut': !displayCompetition}" >
<!--    'hidden-block': !displayCompetition,-->

  </app-competition-embedded>
</div>

<div *ngIf="displayError && isEmbedded">
<!--  No venue data-->
  <mat-card class="pegasus-card">
    <mat-card-content>
      <p>
        Live screen is not installed at this venue. You can view live screen for the following venues:
      </p>
        <ul *ngFor="let venue of venuesList">
          <li *ngIf="venue.live_screen_users.length > 0">
              {{ venue.name }}
          </li>
        </ul>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="!liveScreenReady && !displayError" class="spinner-holder">
  <mat-spinner></mat-spinner>
</div>

<span class="venueName" *ngIf="!isEmbedded">{{venueName}} (v2.1.3)</span>


<ng-template #busyness let-durationData="durationData">
  <div class="traffic">
    <div class="traffic-graph">
      <ng-container
        *ngFor="
          let item of [].constructor(durationData.busyness);
          let i = index
        "
      >
        <img
          [ngClass]="{
            danger: durationData.busyness > 7,
            warning: durationData.busyness <= 7 && durationData.busyness > 4,
            good: durationData.busyness <= 4
          }"
          src="assets/images/live-screen/queue_human.svg"
          alt="progress-bar"
        />
      </ng-container>
    </div>
    <div class="traffic-percentage">
      Traffic {{ durationData.busyness * 10 }}%
    </div>
  </div>
</ng-template>

<ng-template #arrow let-durationData="durationData">
  <div
    [ngClass]="
      durationData.arrow === 'down'
        ? 'metrics-arrow metrics-arrow__down'
        : 'metrics-arrow'
    "
    [ngStyle]="{ display: durationData.arrow === 'none' ? 'none' : 'block' }"
  ></div>
</ng-template>
